import Tooltips from "components/common/tooltipBox/Tooltip";
import { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import roadMap from "assets/images/mapThemesImages/standardTheme.png";
import satellite from "assets/images/mapThemesImages/satellite.png";

const MapTypeControl = ({ map, activeMapType, setActiveMapType }) => {
  const [showMapTypeBtn, SetShowMapTypeBtn] = useState(false);

  // Ref to track the container that holds the map type buttons
  const mapTypeControlRef = useRef(null);

  useEffect(() => {
    // Ensure map type is set correctly when the component first mounts
    if (map) {
      map.setMapTypeId(activeMapType);
    }
  }, [activeMapType, map]); // Update map type whenever activeMapType changes

  useEffect(() => {
    // Close the dropdown when clicking outside of the mapTypeControlRef
    const handleClickOutside = (event) => {
      if (
        mapTypeControlRef.current &&
        !mapTypeControlRef.current.contains(event.target)
      ) {
        SetShowMapTypeBtn(false); // Close the map type selection if the click is outside
      }
    };

    // Adding event listener
    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  // Event handler for changing map type to Roadmap
  const handleRoadmapClick = () => {
    setActiveMapType(window.google.maps.MapTypeId.ROADMAP);
    localStorage.setItem(
      "mapType",
      JSON.stringify(window.google.maps.MapTypeId.ROADMAP)
    );
    SetShowMapTypeBtn(false);
  };

  // Event handler for changing map type to Satellite
  const handleSatelliteClick = () => {
    setActiveMapType(window.google.maps.MapTypeId.HYBRID);
    localStorage.setItem(
      "mapType",
      JSON.stringify(window.google.maps.MapTypeId.HYBRID)
    );

    SetShowMapTypeBtn(false);
  };

  return (
    <div>
      <div
        className="position-absolute map-type-control p-0 "
        ref={mapTypeControlRef}
      >
        <Tooltips msg={"Map Type"}>
          <Button
            onClick={() => {
              SetShowMapTypeBtn(!showMapTypeBtn);
            }}
            className="btn btn-light "
            style={{
              backgroundColor: "white",
            }}
          >
            <i className="bi bi-layers text-dark"></i>
          </Button>
        </Tooltips>
        {showMapTypeBtn && (
          <div className="d-flex gap-2 my-2 bg-light p-2 pb-4">
            <button
              id="roadmap-button"
              className={`btn btn-transparent p-0 border border-3 ${activeMapType === window.google.maps.MapTypeId.ROADMAP || activeMapType === "roadmap" ? " border-primary" : "border-transparent"}  m-0 `}
              onClick={handleRoadmapClick}
              title="Switch to Roadmap view"
              style={{
                width: "74px",
                height: "50px",
              }}
            >
              <img
                style={{
                  objectFit: "cover", // This ensures the image covers the whole button
                  width: "100%", // Make the image take up full width of the button
                  height: "100%", // Make the image take up full height of the button
                }}
                className="fluid"
                src={roadMap}
                alt="Roadmap View"
                width="74px"
                height="50px"
              />
              <label className="mt-2 fw-semibold " style={{ fontSize: "12px" }}>
                RoadMap
              </label>
            </button>
            {/* Satellite Button */}
            <button
              id="satellite-button"
              className={`btn btn-transparent p-0 border border-3 ${activeMapType === window.google.maps.MapTypeId.HYBRID ? " border-primary" : "border-transparent"} m-0`}
              onClick={handleSatelliteClick}
              title="Switch to Satellite view"
              style={{
                width: "74px",
                height: "50px",
              }}
            >
              <img
                style={{
                  objectFit: "cover", // This ensures the image covers the whole button
                  width: "100%", // Make the image take up full width of the button
                  height: "100%", // Make the image take up full height of the button
                }}
                className="fluid"
                src={satellite}
                alt="Satellite View"
                width="74"
                height="50"
              />
              <label className="mt-2 fw-semibold " style={{ fontSize: "12px" }}>
                Satellite
              </label>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MapTypeControl;
