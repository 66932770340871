import { firstLetterUpperCase } from "modules/helper";
import { Button, Card, Spinner } from "react-bootstrap";
import Tooltips from "../tooltipBox/Tooltip";
import React from "react";
import { useAuth } from "components/context/UserContext";
export const UserDrawerComponent = ({
  customerData,
  setMarker = () => {},
  isDownloading,
  downloadCustomerData = () => {},
  downloadLeadsData = () => {},
  downloadArchivedUserData,
  isLeadDataLoading,
  identity,
  isCustomerDataLoading,
  isLeadLoading,
  isArchivedCustomerLoading,
}) => {
  const { user } = useAuth();
  const downloadData = () => {
    if (identity === "leads") {
      downloadLeadsData();
    } else if (identity === "customer") {
      downloadCustomerData();
    } else if (identity === "archive") {
      downloadArchivedUserData();
    }
  };

  // get id of customer / lead /archived customers
  const getId = (customer) => {
    if (customer?.customerId) {
      return customer?.customerId;
    }
    if (customer?.leadId) {
      return customer?.leadId;
    }
    return customer?.id;
  };
  //get assigned employee
  const getAssignedEmployee = (customerData) => {
    // marker?.customerId ? marker.employee : marker?.assignedTo
    if (customerData?.employee) {
      return customerData?.employee;
    }
    if (customerData?.assignedTo) {
      return customerData?.assignedTo;
    }
    return false;
  };
  if (isCustomerDataLoading || isLeadLoading || isArchivedCustomerLoading) {
    return <UserDrawerSkeleton name="customer" />;
  }
  return (
    <div style={{ height: "100vh" }}>
      {customerData?.map((customer, index) => (
        <Card className="m-2 border border-1 border-primary" key={index}>
          <Card
            className="card-body mb-0"
            onClick={() => {
              setMarker(customer);
            }}
          >
            <div className="d-flex mb-2 align-items-center">
              <div className="flex-shrink-0">
                <i
                  className="bi bi-person-circle"
                  style={{
                    color: "#00529D",
                    fontSize: "xx-large",
                  }}
                ></i>
              </div>
              <div className="flex-grow-1 align-items-center ms-2">
                <div>
                  <Tooltips msg={"Click to locate on Map"} direction={"top"}>
                    <h5
                      className="card-title my-0 py-0 me-3 text-wrap"
                      style={{ cursor: "pointer", wordBreak: "break-all" }}
                    >
                      {customer?.name}{" "}
                      <Tooltips
                        msg={identity === "leads" ? "Lead No" : "Customer Id"}
                        direction={"right"}
                      >
                        <span
                          className="badge-label badge bg-primary"
                          style={{ fontSize: "x-small" }}
                        >
                          {getId(customer)}
                        </span>
                      </Tooltips>
                    </h5>
                  </Tooltips>
                  <div className="d-flex gap-2 mt-1 ">
                    <Tooltips msg={"Job Id"} direction={"left"}>
                      <p className="text-muted mb-0">{customer?.jobid}</p>
                    </Tooltips>
                    {customer?.leadNo && (
                      <Tooltips msg={"Lead No"} direction={"right"}>
                        <span
                          className="badge rounded-pill bg-primary-subtle text-primary"
                          style={{ fontSize: "10px", padding: "7px 10px" }}
                        >
                          {customer?.leadNo}
                        </span>
                      </Tooltips>
                    )}
                  </div>
                </div>
                <div className="d-flex gap-2">
                  {customer?.estimateAmount && (
                    <Tooltips msg={"Estimated Amount"} direction={"left"}>
                      <span
                        className="text-dark fw-bold "
                        style={{ fontSize: "12px" }}
                      >
                        ${customer?.estimateAmount?.toLocaleString("en-US")}
                      </span>
                    </Tooltips>
                  )}
                </div>
              </div>
            </div>
            {customer?.material && (
              <>
                <Tooltips msg={"Material"} direction={"left"}>
                  <h6 className="mb-1 " style={{ fontSize: "14px" }}>
                    <i
                      className="mdi mdi-home-roof"
                      style={{ color: "#00529D", fontSize: "18px" }}
                    ></i>
                    &nbsp;
                    {customer.material}
                  </h6>
                </Tooltips>
                <hr className="my-2 p-0" />
              </>
            )}
            {customer?.address && (
              <Tooltips msg={"Location"} direction={"left"}>
                <h6 className="my-2">
                  <i
                    className=" ri-user-location-line"
                    style={{ color: "#00529D" }}
                  ></i>
                  &nbsp;
                  {customer.address},
                </h6>
              </Tooltips>
            )}
            {(customer?.city || customer?.state || customer?.zipcode) && (
              <Tooltips msg={"Location"} direction={"left"}>
                <h6
                  style={{
                    marginLeft: `${customer?.address ? "20px" : "0px"}`,
                  }}
                >
                  {!customer?.address && (
                    <i
                      className=" ri-user-location-line me-1"
                      style={{ color: "#00529D" }}
                    ></i>
                  )}
                  {customer?.city && <>{customer?.city}</>}
                  {customer?.state && (
                    <>,&nbsp;{customer?.state?.toUpperCase()}</>
                  )}
                  {customer?.zipcode && <>,&nbsp;{customer?.zipcode}</>}
                </h6>
              </Tooltips>
            )}

            {customer?.email?.trim()?.length > 0 && (
              <Tooltips msg={"Email"} direction={"left"}>
                <h6 className="mb-2">
                  <i
                    className="bi bi-envelope"
                    style={{ color: "#00529D" }}
                  ></i>
                  &nbsp;
                  <a
                    href={`mailto:${customer.email}`}
                    className="custom-email-mobileNumber"
                  >
                    {customer?.email}
                  </a>
                </h6>
              </Tooltips>
            )}

            <div className="d-flex gap-5 ">
              {customer?.phone?.trim()?.length > 0 && (
                <Tooltips msg={"Phone"} direction={"left"}>
                  <h6 className="mb-2">
                    <i
                      className="bi bi-telephone"
                      style={{ color: "#00529D" }}
                    ></i>
                    &nbsp;
                    <a
                      href={`tel:${customer?.phone?.trim()?.slice(0, 3)}) ${customer?.phone?.trim()?.slice(3, 6)}-${customer?.phone?.trim()?.slice(-4)}`}
                      className="custom-email-mobileNumber"
                    >
                      {`(${customer?.phone?.trim()?.slice(0, 3)}) ${customer?.phone?.trim()?.slice(3, 6)}-${customer?.phone?.trim()?.slice(-4)}`}{" "}
                    </a>
                  </h6>
                </Tooltips>
              )}
              <Tooltips msg={"Logged Date"} direction={"left"}>
                <h6 className="mb-0 ms-4">
                  <i
                    className="bi bi-calendar-date"
                    style={{ color: "#00529D" }}
                  ></i>
                  &nbsp;
                  {customer?.loggedDate}
                </h6>
              </Tooltips>
            </div>
            {getAssignedEmployee(customer) && (
              <>
                <hr className="my-2 p-0" />
                <p className="card-text text-muted m-0 mt-1">
                  <i
                    className="bx bx-user-check "
                    style={{ color: "#00529D" }}
                  ></i>
                  &nbsp; Assigned To
                </p>
                <h6 className="mb-0">{getAssignedEmployee(customer)}</h6>
                {customer?.assigneeEmail && (
                  <Tooltips msg={"Assignee Email"} direction={"left"}>
                    <h6
                      className="mb-2 fs-6 custom-email-mobileNumber"
                      style={{ cursor: "pointer" }}
                    >
                      <a
                        href={`mailto:${customer.assigneeEmail}`}
                        className="custom-email-mobileNumber"
                      >
                        {customer.assigneeEmail}
                      </a>
                    </h6>
                  </Tooltips>
                )}
              </>
            )}
          </Card>
        </Card>
      ))}

      <div
        className="offcanvas-footer  p-3 text-end position-fixed  end-0"
        style={{ bottom: "10%" }}
      >
        {(!user?.salesId || (user?.salesId && user?.allowExport)) && (
          <Tooltips msg={"Download Excel"} direction={"left"}>
            <Button
              disabled={isDownloading || isLeadDataLoading}
              onClick={downloadData}
            >
              {(isDownloading || isLeadDataLoading) && <Spinner size="sm" />}
              <i className="bi bi-file-earmark-excel-fill"></i>
            </Button>
          </Tooltips>
        )}
      </div>
    </div>
  );
};

export const UserDrawerSkeleton = ({ name }) => {
  return (
    <>
      <div>
        <div aria-hidden="true" className="card">
          <div className="card-body">
            <div className="border border-gray p-2 m-2">
              {new Array(6).fill(0).map((item, index) => {
                return (
                  <React.Fragment key={index + name}>
                    <h5 className="card-title placeholder-glow">
                      <span className="placeholder col-6"></span>
                    </h5>
                    <p className="card-text placeholder-glow">
                      <span className="placeholder col-7"></span>
                      <span className="placeholder col-4"></span>
                      <span className="placeholder col-4"></span>
                      <span className="placeholder col-6"></span>
                    </p>
                    <h5 className="card-title placeholder-glow">
                      <span className="placeholder col-6"></span>
                    </h5>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
