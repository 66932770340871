import { Nav, Offcanvas, Tab } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import {
  convertToExcel,
  isValidArray,
  setBodyForCustomerApi,
  setBodyForLeadsApi,
  showWarningMsg,
} from "modules/helper";
import {
  useGetCustomersMutation,
  useGetLeadsMutation,
} from "reduxStore/rtk/EndPoints/SageEndPoint/sageSlice";
import { UserDrawerComponent } from "./UserDrawerComponent";
import { useEffect, useRef, useState } from "react";
import { UserDrawerFooter } from "./UserDrawerFooter";
const UserDrawer = ({
  showUserCanvas,
  toggleUserCanvas,
  leadsData,
  customerData,
  setMarker,
  mapFilters,
  getCustomers,
  isCustomerDataLoading,
  isLeadLoading,
  isArchivedCustomerLoading,
  getLead,
  setLeadData,
  archivedCustomers,
  getArchivedCustomers,
}) => {
  const customerValidate =
    mapFilters?.user?.value === "customer" ||
    mapFilters?.user?.value === "all" ||
    mapFilters?.user?.values === "all" ||
    mapFilters?.user?.values === "customer";
  const leadValidate =
    mapFilters?.user?.value === "leads" ||
    mapFilters?.user?.values === "leads" ||
    mapFilters?.user?.value === "all" ||
    mapFilters?.user?.values === "all";
  const archiveValidate =
    mapFilters?.user?.value === "archived" ||
    mapFilters?.user?.values === "archived" ||
    mapFilters?.user?.value === "all" ||
    mapFilters?.user?.values === "all";
  const [getCustomerData, { isLoading: isDownloading }] =
    useGetCustomersMutation();
  const [getLeads, { isLoading: isLeadDataLoading }] = useGetLeadsMutation();
  const simpleBarRef = useRef(null);
  const [currentTab, setCurrentTab] = useState(
    customerValidate && isValidArray(customerData?.data?.customers)
      ? "customer"
      : leadValidate && isValidArray(leadsData?.data?.lead)
        ? "leads"
        : "archive"
  );
  const customScroll = () => {
    if (simpleBarRef.current) {
      simpleBarRef.current.getScrollElement().scrollTop = 0;
    }
  };
  useEffect(() => {
    customScroll();
  }, [currentTab]);
  const downloadArchivedUserData = async () => {
    try {
      let data = setBodyForCustomerApi(mapFilters);
      let response = await getCustomerData({
        url: "sage/customers/archived",
        export: true,
        data,
      });
      if (response.data.type === "success") {
        convertToExcel(response?.data?.data);
      }
    } catch (error) {
      showWarningMsg();
    }
  };
  const downloadCustomerData = async () => {
    try {
      let data = setBodyForCustomerApi(mapFilters);
      //removed unnecessary if condition here
      // call the download api
      let response = await getCustomerData({
        url: "sage/customers",
        export: true,
        data,
      });
      if (response.data.type === "success") {
        convertToExcel(response?.data?.data);
      }
    } catch (error) {
      showWarningMsg();
    }
  };
  const downloadLeadsData = async () => {
    try {
      let leadsData = setBodyForLeadsApi(mapFilters);
      let response = await getLeads({
        url: "sage/leads",
        export: true,
        data: leadsData,
      });
      if (response.data.type === "success") {
        convertToExcel(response?.data?.data, "leads");
      }
    } catch (error) {
      showWarningMsg();
    }
  };
  //get page information
  const getPageData = () => {
    if (currentTab === "customer") {
      return {
        currentPage: customerData?.data?.currentPage,
        pageLimit: customerData?.data?.pageLimit,
        total: customerData?.data?.total,
      };
    } else if (currentTab === "leads") {
      return {
        currentPage: leadsData?.data?.currentPage,
        pageLimit: leadsData?.data?.pageLimit,
        total: leadsData?.data?.total,
      };
    } else if (currentTab === "archive") {
      return {
        currentPage: archivedCustomers?.data?.currentPage,
        pageLimit: archivedCustomers?.data?.pageLimit,
        total: archivedCustomers?.data?.total,
      };
    }
  };
  return (
    <SimpleBar
      autoHide={false}
      data-simplebar-track="primary"
      style={{ maxHeight: "100vh" }}
    >
      <Offcanvas
        show={showUserCanvas}
        onHide={() => toggleUserCanvas(showUserCanvas)}
        placement="end"
      >
        <Tab.Container>
          <Offcanvas.Header className="border-bottom  text-light" closeButton>
            <Nav
              as="ul"
              variant="pills"
              className="nav-customs nav-danger  "
              role="tablist"
              style={{}}
            >
              <Nav.Item as="li">
                <Nav.Link
                  className={`${currentTab === "customer" ? "active" : ""} h-100 `}
                  onClick={() => {
                    setCurrentTab("customer");
                    let data = setBodyForCustomerApi(mapFilters);
                    if (customerData?.data?.currentPage !== 1) {
                      getCustomers({
                        url: `sage/customers?page=1`,
                        data: data,
                      });
                    }
                  }}
                  disabled={
                    !isValidArray(customerData?.data?.customers) ||
                    !customerValidate
                  }
                  style={{
                    cursor: isValidArray(customerData?.data?.customers)
                      ? "pointer"
                      : "not-allowed",
                  }}
                >
                  Customers
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link
                  className={`${currentTab === "leads" ? "active" : ""} h-100 `}
                  onClick={() => {
                    setCurrentTab("leads");
                    let data = setBodyForLeadsApi(mapFilters);
                    if (leadsData?.data?.currentPage !== 1) {
                      getLead({
                        url: `sage/leads?page=1`,
                        data: data,
                      });
                    }
                  }}
                  disabled={
                    !isValidArray(leadsData?.data?.leads) || !leadValidate
                  }
                  style={{
                    cursor: isValidArray(leadsData?.data?.leads)
                      ? "pointer"
                      : "not-allowed",
                  }}
                >
                  Leads
                </Nav.Link>
              </Nav.Item>
              {/* archive data list button */}
              <Nav.Item as="li">
                <Nav.Link
                  className={`${currentTab === "archive" ? "active" : ""} h-100  `}
                  onClick={() => {
                    setCurrentTab("archive");
                    let data = setBodyForCustomerApi(mapFilters);
                    if (archivedCustomers?.data?.currentPage !== 1) {
                      getArchivedCustomers({
                        url: `sage/customers/archived?page=1`,
                        data: data,
                      });
                    }
                  }}
                  disabled={
                    !isValidArray(archivedCustomers?.data?.customers) ||
                    !archiveValidate
                  }
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Archived
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Offcanvas.Header>
          <Offcanvas.Body data-simplebar-track="primary" className="p-0">
            <SimpleBar
              ref={simpleBarRef}
              autoHide={false}
              data-simplebar-track="primary"
              style={{ maxHeight: "calc(100vh - 200px)" }}
            >
              <div className="tab-content mt-3">
                {/* Customer Tab Content */}
                {currentTab === "customer" && (
                  <div className="tab-pane fade show active">
                    <UserDrawerComponent
                      customerData={customerData?.data?.customers}
                      setMarker={setMarker}
                      isDownloading={isDownloading}
                      downloadCustomerData={downloadCustomerData}
                      downloadLeadsData={downloadLeadsData}
                      isLeadDataLoading={isLeadDataLoading}
                      identity={"customer"}
                      isCustomerDataLoading={isCustomerDataLoading}
                      isLeadLoading={isLeadLoading}
                    />
                  </div>
                )}

                {/* Leads Tab Content */}
                {currentTab === "leads" && (
                  <div className="tab-pane fade show active">
                    <UserDrawerComponent
                      customerData={leadsData?.data?.leads}
                      setMarker={setMarker}
                      isDownloading={isDownloading}
                      downloadCustomerData={downloadCustomerData}
                      downloadLeadsData={downloadLeadsData}
                      isLeadDataLoading={isLeadDataLoading}
                      identity={"leads"}
                      isCustomerDataLoading={isCustomerDataLoading}
                      isLeadLoading={isLeadLoading}
                    />
                  </div>
                )}
                {/* archive tab content */}
                {currentTab === "archive" && (
                  <div className="tab-pane fade show active">
                    <UserDrawerComponent
                      customerData={archivedCustomers?.data?.customers}
                      setMarker={setMarker}
                      isDownloading={isDownloading}
                      downloadCustomerData={downloadCustomerData}
                      downloadLeadsData={downloadLeadsData}
                      downloadArchivedUserData={downloadArchivedUserData}
                      isLeadDataLoading={isLeadDataLoading}
                      identity={"archive"}
                      isCustomerDataLoading={isCustomerDataLoading}
                      isLeadLoading={isLeadLoading}
                      isArchivedCustomerLoading={isArchivedCustomerLoading}
                    />
                  </div>
                )}
              </div>
            </SimpleBar>
          </Offcanvas.Body>
          <UserDrawerFooter
            pageData={getPageData()}
            identity={currentTab}
            getCustomers={getCustomers}
            getLead={getLead}
            getArchivedCustomers={getArchivedCustomers}
            isCustomerDataLoading={isCustomerDataLoading}
            isLeadLoading={isLeadLoading}
            isArchivedCustomerLoading={isArchivedCustomerLoading}
            mapFilters={mapFilters}
            setLeadData={setLeadData}
            customerData={customerData}
            archivedCustomers={archivedCustomers}
            leadsData={leadsData}
            setMarker={setMarker}
            customScroll={customScroll}
          />
        </Tab.Container>
      </Offcanvas>
    </SimpleBar>
  );
};
export default UserDrawer;
