import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { convertToExcel, firstLetterUpperCase } from "modules/helper";
import NearbySalesPersonInfoWindow from "../Maps/GoogleMap/InfowindowHeader/NearbySalesPersonInfoWindow";
import RangeSlider from "../slider/RangeSlider";
import Tooltips from "../tooltipBox/Tooltip";
import { useGetNearbySalesmanMutation } from "reduxStore/rtk/EndPoints/SageEndPoint/sageSlice";
import { useAuth } from "components/context/UserContext";

const SageNearbySalespersonModal = ({
  show,
  closeNearbySalesModal,
  nearbySalesPersonData,
  setNearbySalesPersonData,
  location,
  address,
  salesFilter,
  setSalesFilter = () => {},
  isNearbySalesPersonDataLoading,
}) => {
  const { user } = useAuth();
  const [getNearbySalesPerson, {}] = useGetNearbySalesmanMutation();
  const [range, setRange] = useState([
    salesFilter?.minMiles,
    salesFilter?.maxMiles,
  ]);
  const handleDownload = async () => {
    try {
      let res = await getNearbySalesPerson({
        url: "sage/nearby-sales-person",
        data: {
          latitude: location.lat,
          longitude: location.lng,
          minMiles: salesFilter?.minMiles,
          maxMiles: salesFilter?.maxMiles,
        },
        export: true,
      });
      convertToExcel(res?.data?.data);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };
  useEffect(() => {
    setRange([salesFilter?.minMiles, salesFilter?.maxMiles]);
  }, [salesFilter]);
  return (
    <Modal // search sales person modal
      show={show}
      onHide={() => {
        closeNearbySalesModal();
      }}
      backdrop={"static"}
      keyboard={false}
      id="staticBackdrop"
      centered
      className="custom-modal"
    >
      <Modal.Header
        className="fw-normal mt-1 py-2"
        id="staticBackdropLabel"
        closeButton
      >
        <div className="d-flex gap-2 align-items-center fw-normal card-title fs-6">
          <i
            className="bi bi-geo-alt-fill fs-5"
            style={{ color: "#00529D" }}
          ></i>
          {firstLetterUpperCase(address?.address)},&nbsp;
          {firstLetterUpperCase(address?.city)},&nbsp;
          {address?.state.toUpperCase()},&nbsp;
          {address?.zipcode}
        </div>
      </Modal.Header>
      <Modal.Body className="text-center text-muted pt-1">
        {nearbySalesPersonData && !isNearbySalesPersonDataLoading ? (
          <NearbySalesPersonInfoWindow
            salesFilter={salesFilter}
            setSalesFilter={setSalesFilter}
            salesData={
              nearbySalesPersonData?.length > 0 ? nearbySalesPersonData : []
            }
            marker={location}
            tableHeight="300px"
          />
        ) : (
          <>
            <div className="table-skeleton">
              <div className="table-body">
                {[...Array(9)].map((_, index) => (
                  <div className="table-row placeholder-glow m-1" key={index}>
                    <div
                      className="placeholder col-3 "
                      style={{ height: "29px" }}
                    ></div>
                    <div
                      className="placeholder col-3 "
                      style={{ height: "29px" }}
                    ></div>
                    <div
                      className="placeholder col-3 "
                      style={{ height: "29px" }}
                    ></div>
                    <div
                      className="placeholder col-3 "
                      style={{ height: "29px" }}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        <div className="d-flex align-items-center justify-content-between">
          <RangeSlider
            range={range}
            setRange={setRange}
            disabled={isNearbySalesPersonDataLoading}
            setSalesFilter={setSalesFilter}
            salesFilter={salesFilter}
          />
          {(!user?.salesId || (user?.salesId && user?.allowExport)) && (
            <div className="text-end mt-3">
              <Tooltips msg={"Download Data"}>
                <Button
                  disabled={
                    isNearbySalesPersonDataLoading ||
                    !nearbySalesPersonData?.length
                  }
                  onClick={handleDownload}
                  className="btn btn-success"
                >
                  {isNearbySalesPersonDataLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    <i className="bi bi-file-earmark-excel-fill fs-6"></i>
                  )}
                </Button>
              </Tooltips>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SageNearbySalespersonModal;
