import Tooltips from "components/common/tooltipBox/Tooltip";
import Slider from "rc-slider";
import { Button, Dropdown } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import MapThemes from "./MapThemes";
import { useEffect, useState } from "react";

export const MapHeader = ({
  isApiCalling,
  weatherFilters,
  setWeatherFilters,
  weatherHistoryFilter,
  setWeatherHistoryFilter,
  showWeatherHistoryModal,
  setShowWeatherHistoryModal,
  getWeatherData,
  isLoadingWeatherData,
  handleWeatherHistoryModal,
  sliderStyle,
  hailSize,
  mapThemes,
  setMapThemes,
  setHailSize,
  handleHailSize,
  toggleRightCanvas,
  setMarker,
  countForMapFilters,
  isRight,
  handleSalesFilter,
  showSalesModal,
  clearAllMarkersAndCluster,
  setShowSalesModal,
  resetFilter,
  setSalesFilter,
  salesFilter,
  mapRef,
  searchedLocation,
  setSearchedLocation,
  activeMapType,
  setActiveMapType,
}) => {
  const [localWeatherFilters, setLocalWeatherFilter] = useState(weatherFilters);
  const [localHailSize, setLocalHailsize] = useState(0);
  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setWeatherFilters(localWeatherFilters);
    }, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [localWeatherFilters]);
  useEffect(() => {
    setLocalWeatherFilter(weatherFilters);
  }, [weatherFilters]);
  const nextDate = () => {
    setLocalWeatherFilter((prev) => {
      const nextDate = new Date(prev.date);
      nextDate.setDate(prev.date.getDate() + 1);
      return { ...prev, date: nextDate };
    });
  };

  // Function to go to the previous date
  const prevDate = () => {
    setLocalWeatherFilter((prev) => {
      const previousDate = new Date(prev.date); // Rename this to avoid conflict
      previousDate.setDate(prev.date.getDate() - 1);
      return { ...prev, date: previousDate };
    });
  };
  return (
    <div className="mx-1 d-block d-sm-flex d-md-flex d-lg-flex justify-content-center mt-2 ">
      <div
        className="d-block d-sm-flex d-md-flex d-lg-flex justify-content-center"
        style={{ zIndex: 10, backgroundColor: "white" }}
      >
        <div lg={2} className="m-1">
          <Tooltips msg={"Map Styles"}>
            <Dropdown align={"end"} className="">
              <Dropdown.Toggle
                disabled={
                  isApiCalling ||
                  activeMapType === window.google.maps.MapTypeId.HYBRID
                }
                variant="outline"
                className=" btn btn-subtle-primary dropdown-arrow-hidden"
              >
                <i className="ri-palette-fill"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="my-1" style={sliderStyle}>
                <MapThemes
                  map={mapRef}
                  mapThemes={mapThemes}
                  setMapThemes={setMapThemes}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Tooltips>
        </div>

        <div className="m-1 d-flex">
          <Button
            disabled={isApiCalling}
            variant="primary"
            title="Prev"
            size={"sm"}
            onClick={prevDate}
          >
            <i className="bi bi-caret-left"></i>
          </Button>
          <Flatpickr
            disabled={isApiCalling}
            style={{ width: "110px" }}
            className="form-control"
            value={localWeatherFilters.date}
            onChange={(date) => {
              setWeatherFilters((prev) => ({
                ...prev,
                date: date[0],
              }));
              setLocalWeatherFilter((prev) => ({
                ...prev,
                date: date[0],
              }));
            }}
            options={{
              dateFormat: "m-d-Y",
            }}
          />
          <Button
            disabled={isApiCalling}
            variant="primary"
            title="Next"
            size={"sm"}
            onClick={nextDate}
          >
            <i className="bi bi-caret-right"></i>
          </Button>
          <div className=" d-flex ">
            <Tooltips msg={"Hail Size"}>
              <Dropdown align={"start"} className="ms-2 ">
                <Dropdown.Toggle
                  disabled={isApiCalling}
                  variant="outline"
                  className=" btn btn-subtle-primary dropdown-arrow-hidden position-relative"
                >
                  {hailSize > 0 && (
                    <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                      <span className="visually-hidden">Filters</span>
                    </span>
                  )}
                  <i className="bi bi-cloud-lightning-rain-fill"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className="my-1 range-dropdown"
                  style={sliderStyle}
                >
                  {/* <Dropdown.Item > */}
                  <div className="m-2 py-2  ">
                    <div className="d-flex my-2 justify-content-between">
                      <div>Hailsize </div>
                      <div>{localHailSize}</div>
                    </div>
                    <Slider
                      disabled={isApiCalling}
                      min={0}
                      max={6}
                      value={localHailSize}
                      step={0.25}
                      className="text-primary"
                      onChange={(value) => setLocalHailsize(value)}
                      onChangeComplete={(nextValue) => {
                        handleHailSize(nextValue);
                        setHailSize(nextValue);
                      }}
                      styles={{
                        track: {
                          background: `linear-gradient(to right, yellow, red)`,
                        },
                      }}
                    />
                  </div>
                  {/* </Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            </Tooltips>
          </div>
        </div>
        <div className="d-flex  ">
          <div className="text-start m-1 position-relative  ">
            <Tooltips msg={"Apply Filter"}>
              <Button
                disabled={isApiCalling}
                onClick={() => {
                  toggleRightCanvas(isRight);
                }}
                className="btn btn-subtle-primary "
              >
                <i className="bi bi-funnel-fill"></i>
              </Button>
            </Tooltips>
            {countForMapFilters > 0 && (
              <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                <span className="visually-hidden">Filters</span>
              </span>
            )}
          </div>
          {/* <div lg={2} className="text-start m-1">
            <Tooltips msg={"Find Near by Sales Person"}>
              <Button
                disabled={isApiCalling}
                onClick={() => handleSalesFilter()}
                className="btn btn-subtle-primary "
              >
                <i className="bx bx-user-check  fs-5"></i>
              </Button>
            </Tooltips>
            <SalesFilterModal
              show={showSalesModal}
              handleClose={() => setShowSalesModal(false)}
              setSalesFilter={setSalesFilter}
              salesFilter={salesFilter}
              mapRef={mapRef}
              searchedLocation={searchedLocation}
              setSearchedLocation={setSearchedLocation}
            />
          </div>
          <div lg={2} className="text-start m-1">
            <Tooltips msg={" Weather History"}>
              <Button
                disabled={isApiCalling}
                onClick={() => handleWeatherHistoryModal()}
                className="btn btn-subtle-primary "
              >
                <i className="mdi mdi-cloud-search fs-6"></i>
              </Button>
            </Tooltips>
            <WeatherHistory
              show={showWeatherHistoryModal}
              isLoadingWeatherData={isLoadingWeatherData}
              getWeatherData={getWeatherData}
              handleClose={() => setShowWeatherHistoryModal(false)}
              setWeatherHistoryFilter={setWeatherHistoryFilter}
              weatherHistoryFilter={weatherHistoryFilter}
              mapRef={mapRef}
            />
          </div> */}
          <div lg={2} className="m-1">
            <Tooltips msg={"Reset Filters"} direction={"bottom"}>
              <Button
                disabled={isApiCalling}
                className="btn btn-subtle-danger"
                onClick={() => {
                  resetFilter();
                  setMarker(null);
                  clearAllMarkersAndCluster();
                }}
              >
                <i className="ri-refresh-line"></i>
              </Button>
            </Tooltips>
          </div>
        </div>
      </div>
    </div>
  );
};
