import moment from "moment";

const formatDate = (date, format = "DD MMM YY, h:mm a") => {
  const momentDate = moment(date);
  const todayDate = moment().startOf("day");
  if (momentDate.isSame(todayDate)) {
    return momentDate.fromNow();
  }
  // Customize the format string to include the correct AM/PM indicator
  const formattedDate = moment(date).format(format.replace(/a/g, "A"));

  return formattedDate;
  // return moment(date).format(format);
};
const DateFormat = (cell) => {
  return moment(cell.value).format("h:mm a"?.replace(/a/g, "A")) || "-";
};
const formatTime = (time = 1662379200000, format = "DD MMM YY, h:mm a") => {
  // const dateString = "Thu Aug 01 2024 12:00:00 GMT+0530 (India Standard Time)";

  // Example date string

  // Parse the date string using Moment.js
  const date = moment(
    time,
    "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (India Standard Time)",
    true
  );
  // Check if the date is valid
  if (!date.isValid()) {
    return time;
  } else {
    // Format the time in HH:mm:ss
    const formattedTime = date.format("HH:mm:ss");
    return formattedTime;
  }
};
const convertToTime = (timeStr) => {
  // Parse the time string into a Moment object
  const time = moment(timeStr, "HH:mm:ss");

  // Check if the time is valid
  if (!time.isValid()) {
    throw new Error('Invalid time format. Please use "HH:mm:ss".');
  }

  return time;
};
export { formatDate, DateFormat, formatTime, convertToTime };
