import Tooltips from "../tooltipBox/Tooltip";
import customer from "../../../assets/images/customer.png";
import leads from "../../../assets/images/leads.png";
import { setBodyForCustomerApi, setBodyForLeadsApi } from "modules/helper";
import { formatDateCustomerFilter } from "../CollumnFormator";
export const UserDrawerFooter = ({
  pageData,
  identity,
  getCustomers,
  getLead,
  isCustomerDataLoading,
  isLeadLoading,
  mapFilters,
  getArchivedCustomers,
  customerData,
  leadsData,
  setMarker,
  customScroll,
  archivedCustomers,
  isArchivedCustomerLoading,
}) => {
  const handleNextAndPreviousClick = (type) => {
    let pageNumber = null;
    customScroll();
    if (type === "next") {
      pageNumber = pageData?.currentPage + 1;
    } else {
      pageNumber = pageData?.currentPage - 1;
    }
    setMarker(null);
    let data = setBodyForCustomerApi(mapFilters);
    let leadsData = setBodyForLeadsApi(mapFilters);

    if (identity === "leads") {
      getLead({
        url: `sage/leads?page=${pageNumber}`,
        data: leadsData,
      });
    }
    if (identity === "customer") {
      getCustomers({
        url: `sage/customers?page=${pageNumber}`,
        data: data,
      });
    }
    if (identity === "archive") {
      getArchivedCustomers({
        url: `sage/customers/archived?page=${pageNumber}`,
        data: data,
      });
    }
  };
  //get number of data shown on page
  const getTotalCount = () => {
    if (identity === "leads") {
      return leadsData?.data?.leads?.length?.toLocaleString("en-US");
    } else if (identity === "customer") {
      return customerData?.data?.customers?.length?.toLocaleString("en-US");
    } else if (identity === "archive") {
      return archivedCustomers?.data?.customers?.length?.toLocaleString(
        "en-US"
      );
    }
    return 0;
  };
  return (
    <div className="offcanvas-footer border-top p-3 bg-light  text-center bottom-0 end-0">
      {!isCustomerDataLoading &&
        !isLeadLoading &&
        !isArchivedCustomerLoading && (
          <div>
            <p className="card-text text-left" style={{ paddingBottom: "1pc" }}>
              <span style={{ float: "left" }}>
                <Tooltips msg={"pages"}>
                  <span>
                    {pageData?.currentPage ?? 0}/{pageData?.pageLimit ?? 0}
                  </span>
                </Tooltips>
              </span>
              <img
                src={identity === "leads" ? leads : customer}
                style={{ height: "24px" }}
                alt={identity}
              />
              Showing <b> {getTotalCount()} </b>
              of <b> {pageData?.total?.toLocaleString("en-US") ?? 0} </b>
            </p>
          </div>
        )}
      <div className="row">
        <div className="col-6">
          <button
            type="button"
            className="btn btn-primary w-100"
            id="reset-layout"
            disabled={
              pageData?.currentPage > 1
                ? false
                : true || isCustomerDataLoading || isLeadLoading
            }
            style={{
              cursor: pageData?.currentPage > 1 ? "pointer" : "not-allowed",
              backgroundColor: pageData?.currentPage > 1 ? "" : "gray",
            }}
            onClick={() => {
              handleNextAndPreviousClick("prev");
            }}
          >
            Previous
          </button>
        </div>
        <div className="col-6">
          <span
            className="btn btn-primary w-100"
            disabled={
              pageData?.currentPage < pageData?.pageLimit
                ? false
                : true || isCustomerDataLoading || isLeadLoading
            }
            onClick={() => {
              if (
                pageData?.currentPage < pageData?.pageLimit &&
                !isCustomerDataLoading &&
                !isLeadLoading
              ) {
                handleNextAndPreviousClick("next");
              }
            }}
            style={{
              cursor:
                pageData?.currentPage < pageData?.pageLimit
                  ? "pointer"
                  : "not-allowed",
              background:
                pageData?.currentPage < pageData?.pageLimit ? "" : "gray",
            }}
          >
            Next
          </span>
        </div>
      </div>
    </div>
  );
};
